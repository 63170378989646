import React, { useState } from "react";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";

import CallButton from "../../components/CallButton";
import MailButton from "../../components/MailButton";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { LOCATIONS } from "../../config/cities";

import "../../styles/contact.scss";
import CallCta from "../../components/CallCta";
import {
  GTM_LEAD_UMOW_SIE,
  GTM_PHONE_CONTACT_PAGE,
} from "../../config/gtm_classes";

const CityItem = ({ city = "", link = "" }) => (
  <Link className="contact_list_item" to={`/${link}/#cennik`} title={city}>
    <div>
      <h4>{city}</h4>
    </div>
    <Button type="secondary" size="M">
      <span>Wybierz</span>
    </Button>
  </Link>
);

const ContactInfo = () => {
  const [isModalOpened, setModalOpened] = useState(false);

  return (
    <div className="contact_nav_button">
      <AnchorLink
        href="#kontakt"
        title="Umów się"
        className={`contact_nav_button_desktop_label ${GTM_LEAD_UMOW_SIE}`}
      >
        Umów się
      </AnchorLink>
      <div
        className="contact_nav_button_mobile_label"
        onClick={() => setModalOpened(true)}
      >
        Kontakt
      </div>
      <Modal
        className="contact_modal"
        isOpened={isModalOpened}
        onClose={() => setModalOpened(false)}
      >
        <div>
          <RegistrationInfo />
        </div>
      </Modal>
    </div>
  );
};

const RegistrationInfo = () => (
  <>
    <div className="registration_wrappper">
      <h4>Rejestracja telefoniczna:</h4>
      <div className="registration_hours">
        <p>
          poniedziałek - piątek: <strong>9:00⁃20:00</strong>
        </p>
      </div>
      <a
        href="tel:+48781445685"
        title="+48781445685"
        className={`contact_info_wrapper ${GTM_PHONE_CONTACT_PAGE}`}
      >
        <CallButton className="phone_button" type="dark" size="S" />
        <span className="phone-number">+48 781 445 685</span>
      </a>
      <a
        href="mailto:zbadamy@twojeznamiona.pl"
        title="zbadamy@twojeznamiona.pl"
        className="contact_info_wrapper"
      >
        <MailButton className="mail_button" />
        <span className="phone-number">zbadamy@twojeznamiona.pl</span>
      </a>
    </div>
    <div className="operations_wrappper">
      <h4>Działy Operacyjne</h4>
      <div className="operations_item">
        <div className="contact_person">Badania pracownicze i Współpraca B2B</div>
        <a
          href="mailto:wspolpraca@twojeznamiona.pl"
          title="wspolpraca@twojeznamiona.pl"
          className="contact_info_wrapper"
        >
          <MailButton className="mail_button" />
          <span className="phone-number">wspolpraca@twojeznamiona.pl</span>
        </a>
      </div>
      <div className="operations_item">
        <div className="contact_person">Dział marketingu</div>
        <a
          href="mailto:marketing@twojeznamiona.pl"
          title="marketing@twojeznamiona.pl"
          className="contact_info_wrapper"
        >
          <MailButton className="mail_button" />
          <span className="phone-number">marketing@twojeznamiona.pl</span>
        </a>
      </div>

      <div className="operations_item">
        <div className="contact_person">Dział Rekrutacji</div>
        <a
          href="mailto:praca@twojeznamiona.pl"
          title="praca@twojeznamiona.pl"
          className="contact_info_wrapper"
        >
          <MailButton className="mail_button" />
          <span className="phone-number">praca@twojeznamiona.pl</span>
        </a>
      </div>
      <div className="operations_item">
        <div className="contact_person">Dział Finansowy</div>
        <a
          href="mailto:rozliczenia@twojeznamiona.pl"
          title="rozliczenia@twojeznamiona.pl"
          className="contact_info_wrapper"
        >
          <MailButton className="mail_button" />
          <span className="phone-number">rozliczenia@twojeznamiona.pl</span>
        </a>
      </div>
      {/* <div className="operations_item">
        <div className="contact_person">Dział Obsługi Pacjenta</div>
        <a
          href="mailto:aleksandra.kalenik@twojeznamiona.pl"
          title="aleksandra.kalenik@twojeznamiona.pl"
          className="contact_info_wrapper"
        >
          <MailButton className="mail_button" />
          <span className="phone-number">
            aleksandra.kalenik@twojeznamiona.pl
          </span>
        </a>
      </div> */}
      {/* <div className="operations_item">
        <div className="contact_person">Dział Operacyjny</div>
        <a
          href="mailto:patrycja.obara@twojeznamiona.pl"
          title="patrycja.obara@twojeznamiona.pl"
          className="contact_info_wrapper"
        >
          <MailButton className="mail_button" />
          <span className="phone-number">patrycja.obara@twojeznamiona.pl</span>
        </a>
      </div> */}
      <div className="operations_item">
        <div className="contact_person">W innych kwestiach</div>
        <a
          href="mailto:kontakt@twojeznamiona.pl"
          title="kontakt@twojeznamiona.pl"
          className="contact_info_wrapper"
        >
          <MailButton className="mail_button" />
          <span className="phone-number">kontakt@twojeznamiona.pl</span>
        </a>
      </div>
    </div>
  </>
);

const ContactPage = ({ location }) => (
  <Layout constactCtaComponent={<ContactInfo />} location={location}>
    <Helmet>
      <title>Kontakt</title>
    </Helmet>
    <CallCta isLanding={false} />

    <div className="contact">
      <div className="contact_cities">
        <h1>Nasze ośrodki</h1>
        <div className="contact_cities_instructions">
          <p className="instructions_cta">
            <strong>Wybierz miasto</strong>, w którym chcesz się zbadać.
          </p>
          <a className="anchor" id="kontakt"></a>
          <p>
            Dynamicznie zwiększamy obszar naszej działalności, aby już wkrótce
            działać w Twoim miejscu zamieszkania!
          </p>
        </div>

        <div className="contact_cities_list_wrapper">
          {LOCATIONS.filter((location) => location.link).map((location) => (
            <CityItem
              key={location.city}
              city={location.city}
              link={location.link}
            />
          ))}
        </div>
      </div>
      <div className="contact_details_wrappper">
        <div className="contact_details_wrappper_sticky">
          <RegistrationInfo />
        </div>
      </div>
    </div>
  </Layout>
);

export default ContactPage;
